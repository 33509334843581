<template>
    <div class="Home">

      <page-header/>

      <section class="uk-section-small">
        <div class="uk-container">
          <div>
            <div class="uk-grid">
              <div class="uk-width-1-2">
                <a class="uk-button uk-button-small uk-button-primary" @click="$router.go(-1)">
                  <span uk-icon="icon: chevron-left; ratio: .75;" class="uk-icon"></span>
                  {{$t("orgaExam.dataId.back")}}
                </a>
              </div>
              <div class="uk-width-1-2">
                <p class="uk-text-right soctial">
                  <social-sharing :url="`/organization/answer/`+answer.id"
                      :title="answer.title"
                      ::description="answer.description"
                      :quote="answer.title"
                      :hashtags="answer.keyword"
                      twitter-user="vuejs"
                      :media="answer.imageurl"
                      inline-template>
                    <div>
                      <network style="background:#09f" network="facebook">
                        <i class="fa fa-fw fa-facebook"></i>
                      </network>
                      <network style="background:#d34836" network="googleplus">
                        <i class="fa fa-fw fa-google-plus"></i>
                      </network>
                      <network style="background:#0073b1" network="linkedin">
                        <i class="fa fa-fw fa-linkedin"></i>
                      </network>
                      <network style="background:#e60023" network="pinterest">
                        <i class="fa fa-fw fa-pinterest"></i>
                      </network>

                      <network style="background:#1da1f2" network="twitter">
                        <i class="fa fa-fw fa-twitter"></i>
                      </network>

                    </div>
                  </social-sharing>
                </p>
              </div>
            </div>

            <div class="uk-grid">
              <div class="uk-width-3-5@m">
                <fieldset data-uk-margin>
                  <vue-plyr data-width="400" data-height="300" v-if="answer.datatype == 2">
                    <video poster :src="answer.imageurl">
                      <source :src="answer.imageurl" type="video/mp4" size="720">
                      <source :src="answer.imageurl" type="video/mp4" size="1080">
                      <track
                        kind="captions"
                        label="English"
                        srclang="kr"

                        default
                      >
                    </video>
                  </vue-plyr>
                  <vue-plyr data-width="400" data-height="300" v-else-if="answer.datatype == 3">
                    <audio>
                      <source :src="answer.imageurl" type="audio/mp3">
                      <source :src="answer.imageurl" type="audio/ogg">
                    </audio>
                  </vue-plyr>
                  <img
                    v-else
                    :src="answer.imageurl"
                    :data-src="answer.imageurl"
                    width="800"
                    height="300"
                    :alt="answer.title"
                    class="lazy"
                    data-uk-img
                  >

                  <div class="uk-text-right">

                    <star-rating
                      :size= '1'
                      :score="totalrate"
                      :color= 'color'
                      :maxScore= '5'
                      :readOnly= 'true'
                    ></star-rating>

                  </div>
                </fieldset>
              </div>
              <div class="uk-width-2-5@m">
                <header class="uk-comment-header uk-position-relative">
                  <h3>{{answer.title}}</h3>
                  <div class="uk-width-1-1">
                    <div class="uk-text-right">
                      <ul
                        class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top"
                        uk-grid
                      >
                        <li>
                          <p style="color:#fff" class="uk-label uk-padding uk-padding-small">P</p>
                        </li>
                        <li>
                          <h1 style="font-size:25px; color:#666">
<!--                            <b>{{answer.price|currency}}</b>-->
                            <b>{{answer.price}}</b>
                          </h1>
                        </li>
                      </ul>
                    </div>
                    <div class="uk-flex" hidden>
                      <div class="uk-card-small uk-card-default uk-card-body">
                        <img
                          class="uk-border-circle"
                          src="../../assets/user_pro.png"
                          alt="Border pill"
                        >
                        <span>{{$t("orgaExam.dataId.name")}}</span>
                        <br>
                        <a href="#">sdfsdf</a>
                      </div>
                    </div>

                    <p class="uk-text-justify">{{answer.description}}</p>
                  </div>
                </header>
                <div>
                  <button
                    class="uk-margin-small uk-width-1-1 uk-button uk-button-default"
                    @click="addItem"
                  >
                    <span uk-icon="cart" class="uk-icon"></span> {{$t("orgaExam.dataId.addCard")}}
                  </button>
                </div>

                <div>
                  <button type="button" class="uk-margin-small uk-width-1-1 uk-button uk-button-primary">
                    {{$t("orgaExam.dataId.purch")}}</button>
                </div>
              </div>
            </div>
            <hr>

            <div class="uk-grid">
              <div class="uk-width-3-5@m">
                <h4>{{$t("orgaExam.dataId.key")}}</h4>

                <router-link
                  v-for="(keyitem, keyindex) in keywords"
                  :key="keyindex"
                  class="uk-margin-small-right uk-margin-small-bottom uk-button uk-button-default uk-button-small"
                  title="keyitem"
                  tag="a"
                  :to="{name: 'datamarket',
                      query: {
                mtype0: keyitem.datatype,
                keyword:keyitem.keyword }}">
                <span>{{keyitem.keyword}}</span>
                </router-link>

                <h4>{{$t("orgaExam.dataId.reData")}}</h4>

                <div
                  class="uk-grid-small uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m"
                  uk-grid="masonry: true"
                  uk-lightbox
                >
                  <div v-for="(item, index) in relateddatas" :key="index">
                    <div class="uk-card uk-card-default uk-card-body uk-padding-remove">
                      <a class="uk-inline" :href="item.imageurl" :data-caption="answer.title">
                        <vue-plyr data-width="500" data-height="300" v-if="item.datatype == 2">
                          <video poster :src="item.imageurl">
                            <source :src="item.imageurl" type="video/mp4" size="720">
                            <source :src="item.imageurl" type="video/mp4" size="1080">
                            <track
                              kind="captions"
                              label="English"
                              srclang="kr"

                              default
                            >
                          </video>
                        </vue-plyr>
                        <vue-plyr data-width="500" data-height="300" v-else-if="item.datatype == 3">
                          <audio>
                            <source :src="item.imageurl" type="audio/mp3">
                            <source :src="item.imageurl" type="audio/ogg">
                          </audio>
                        </vue-plyr>
                        <img
                          v-else
                          :src="item.imageurl"
                          :data-src="item.imageurl"
                          width="500"
                          height="300"
                          :alt="item.title"
                          class="lazy"
                          data-uk-img
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-2-5@m">
                <app-review
                  v-if="answer.id"
                  :type="type"
                  :id="answer.id"
                  v-on:dataa="handleReviews"
                ></app-review>
              </div>
            </div>
          </div>
        </div>
      </section>

      <page-footer/>
    </div>

</template>

<script>
import AnswersService from '@/services/AnswersService'
import { mapActions, mapState } from 'vuex'
import Review from '@/components/GlobViews/Review'
import starRating from "vue-star-rate";
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
export default {
  components: {
    appReview: Review,
    starRating,
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'route', 'isLoading'])
  },
  data () {
    return {
      answer: {},
      loaderColor: '#5cb85c',
      loaderSize: '10px',
      keywords: null,
      relateddatas: null,
      totalrate: 0,
      type: 'data',
      color:'#0281b8',
    }
  },
  watch: {
    $route (to, from) {
      if (from.params.answerId !== to.params.answerId) {
        AnswersService.show(to.params.answerId).then(res => {
          this.answer = res.data
        })
      }
    }
  },
  created () {},
  async mounted () {
    const answerId = this.route.params.answerId
    this.answer = (await AnswersService.show(answerId)).data
    this.loadRelatedData(this.answer.datatype, this.answer.keyword)

    AnswersService.getKeys({ datatype: this.answer.datatype }).then(res => {
      const { data } = res
      this.keywords = data
      if (!this.keywords.length) {
        return
      }
    })
  },

  methods: {
    loadRelatedData (datatype, keyword) {
      // console.log(datatype, keyword)
      AnswersService.getAnswersByPage({
        mtype: datatype,
        keyword: keyword,
        size: 6
      }).then(res => {
        const { data } = res
        this.relateddatas = data.content
        if (!this.relateddatas.length) {
          return
        }
      })
    },
    handleReviews (e) {
      console.log('answer event ftw', e)
      this.totalrate = 0
      // eslint-disable-next-line no-unused-vars
      e.reviews.forEach((a, index) => {
        if (a.rate) this.totalrate += a.rate
      })

      if (e.reviews) {
        this.totalrate = this.totalrate / e.reviews.length
      }
    },
    ...mapActions(['updateCart']),
    addItem () {
      const order = {
        item: Object.assign({}, this.answer),
        quantity: 1,
        isAdd: true,
        type: 'data'
      }
      // console.log(order);
      this.updateCart(order)
    }
  }
}
</script>

<style scoped>
</style>
